@import '../../../../scss/theme-bootstrap';

.offers-banner {
  text-align: center;
  padding: 0 25px;
  min-height: $offer-banner-height;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint($medium-up) {
    height: $offer-banner-height;
  }
  @include breakpoint($xlarge-up) {
    max-width: $max-width-large;
    margin: 0 auto;
  }
  &__link {
    font-size: 14px;
    letter-spacing: 0;
    font-family: $primary-font;
    font-weight: 500;
    color: $color-off-black;
    @if $hit_area_update == true {
      min-height: 24px;
    }
    @include breakpoint($portrait-up) {
      @if $hit_area_update == true {
        min-height: unset;
      }
    }
  }
  a:hover {
    text-decoration: none;
  }
}
